/* eslint-disable no-unused-vars */
import MintComponent from '../../components/mint/Mint.vue';
import Navbar from '../../components/navbar/Navbar.vue';

export default {
  name: 'PreSale',
  components: {
    MintComponent,
    Navbar,
  },
};
