<template>
    <div>
        <!-- VUEJS COMPONENTS -->
		<Loader :loading="loading"></Loader>
		<Modal :message="modalMessage" :showModal="showModal" @close-modal-instance="close"></Modal>
		<!-- END VUEJS COMPONENTS -->
        <Navbar></Navbar>
		<div class="hero" id="home">
			<!-- <div class="cloud"></div> -->
			<div class="hero-container">
				<div id="onis">
					<img src="../../static/img/onis/hero-medusa-oni-2.png" id="oni1" class="oni" alt="Medusa Oni">
					<img src="../../static/img/onis/hero-devil-oni.png" id="oni2" class="oni" alt="Devil Oni">
					<img src="../../static/img/onis/hero-gamer-oni.png" id="oni3" class="oni" alt="Gamer Oni">
				</div>
				
				<header id="header">
					<div>
					<img src="../../static/img/logo2.png" id="logo" alt="Oni Squad Logo">

					<div id="main-copy">
						<p>Oni Squad NFT holders can play the Oni Mansion Gaming Event this December. They will receive a small MATIC (polygon) airdrop to cover gas costs. Oni NFTs will be important in future play to earn (p2e) games developed by Oni Squad. Future drops and rewards for Oni Squad NFT holders will be much more substantial in the future.</p>
						<!-- <p>Haunting the Ethereum blockchain starting<br>October 29, 2021 &#x40; 2pm PST</p> -->
						<div id="mint" class="container">
							<!-- <h2 style="margin-bottom: 30px; margin-left: 15px;">Mint</h2> -->

							<MintComponent></MintComponent>
						</div>
						
					</div>
				</div>
				</header>
			</div>
		</div>
    </div>
    
</template>
<script src="./pre-sale.js"></script>
